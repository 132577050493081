/* eslint-disable react-refresh/only-export-components */
import "@bracket/styles/index.css"
import "@bracket/styles/tournament-progress-bar.css"

import { Layout } from "@bracket/components/Layout"
import { initSentry, sentryCreateBrowserRouter } from "@bracket/lib/sentry"
import { trpc, trpcReactClient } from "@bracket/lib/trpc"
import { collectiveLoader, homeLoader } from "@bracket/pages/loaders"
import { AnalyticsProvider } from "@bracket/providers/AnalyticsProvider"
import { DynamicProvider } from "@bracket/providers/DynamicProvider"
import { LoginProvider } from "@bracket/providers/LoginProvider"
import { ViemProvider } from "@bracket/providers/ViemProvider"
import { Modal } from "@bracket/types"
import { DynamicUserProfile } from "@dynamic-labs/sdk-react-core"
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector"
import { QueryClient, QueryClientProvider, keepPreviousData } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { StrictMode, lazy } from "react"
import { createRoot } from "react-dom/client"
import { RouterProvider } from "react-router-dom"

initSentry(import.meta.env.VITE_SENTRY_DSN, import.meta.env.VITE_SENTRY_HOST)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.PROD, // Disable retries in development
      placeholderData: keepPreviousData, // Prevent flash of empty content
    },
  },
})

// TODO: how can we further split the bundle?
const HomePage = lazy(() => import("./pages/HomePage"))
const CollectivePage = lazy(() => import("./pages/CollectivePage"))
const ErrorPage = lazy(() => import("./pages/ErrorPage"))
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"))

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: homeLoader(queryClient),
      },
      {
        path: "/faq",
        element: <HomePage />,
        loader: homeLoader(queryClient, Modal.GeneralFAQs),
      },
      {
        path: "/faqs",
        element: <HomePage />,
        loader: homeLoader(queryClient, Modal.GeneralFAQs),
      },
      {
        path: "/trustee",
        element: <HomePage />,
        loader: homeLoader(queryClient, Modal.TrusteeFAQs),
      },
      {
        path: "/trustees",
        element: <HomePage />,
        loader: homeLoader(queryClient, Modal.TrusteeFAQs),
      },
      {
        path: "/:slug",
        element: <CollectivePage />,
        loader: collectiveLoader(queryClient),
      },
    ],
  },
  {
    path: "/analytics",
    element: <AnalyticsPage />,
  },
])

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AnalyticsProvider
      posthogHost={import.meta.env.VITE_POSTHOG_HOST}
      posthogKey={import.meta.env.VITE_POSTHOG_API_KEY}
    >
      <trpc.Provider client={trpcReactClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <DynamicProvider environmentId={import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID}>
            <ViemProvider primaryChainId={import.meta.env.VITE_PRIMARY_CHAIN}>
              <DynamicWagmiConnector>
                <LoginProvider>
                  <RouterProvider router={router} />
                </LoginProvider>
                <DynamicUserProfile />
              </DynamicWagmiConnector>
            </ViemProvider>
          </DynamicProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </trpc.Provider>
    </AnalyticsProvider>
  </StrictMode>,
)
