import type { PackResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

function isPackResponse(item: unknown): item is PackResponse {
  return item !== undefined
}

export async function fetchInitialPack(queryClient: QueryClient, code: string) {
  const queryKey = getQueryKey(trpc.pack.get, { code }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.pack.get.query({ code }),
  })
}

export function usePack(code?: string, initialData?: PackResponse[]) {
  return trpc.pack.get.useQuery(code ? { code } : skipToken, {
    ...(initialData && { initialData }),
  })
}

export function usePacks(codes: string[]) {
  const queries = trpc.useQueries((t) => codes.map((code) => t.pack.get({ code })))
  const packs = queries
    .map((query) => query.data)
    .flat()
    .filter(isPackResponse)
  return packs
}

export function usePackSuspense(code: string, initialData?: PackResponse[]) {
  return trpc.pack.get.useSuspenseQuery(
    { code },
    {
      ...(initialData && { initialData }),
    },
  )
}
