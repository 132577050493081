import { normTokenAmount } from "@backend/lib/utils"
import { useFanStore } from "@bracket/stores/fan"
import { useReadContract } from "wagmi"
import { erc20Abi } from "viem"
import { useContractToken } from "@bracket/hooks/useContractToken"

export function useTokenBalance(decimals = 18) {
  const fan = useFanStore((s) => s.fan)
  const { address } = useContractToken()
  const { data: rawBalance } = useReadContract({
    abi: erc20Abi,
    address,
    functionName: "balanceOf",
    args: [fan?.address as `0x${string}`],
    query: {
      enabled: !!address && !!fan?.address,
      refetchInterval: 5_000, // Default polling interval
    },
  })

  const balance = normTokenAmount(rawBalance ?? 0n, decimals)

  return { balance }
}
