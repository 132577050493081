import type { Fan, SocialResponse } from "@backend/types"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useToast } from "@bracket/hooks/useToast"
import { trpcClient as trpc } from "@bracket/lib/trpc"

const events = {
  link: "social:profile_link",
  update: "social:profile_update",
  primary: "social:primary_update",
}

// NOTE: Should NOT update fan store to prevent race conditions with Dynamic callbacks
export function useSocialMutations() {
  const analytics = useAnalytics(events)
  const { showWarning } = useToast()

  async function fetchExistingSocials(fan: Fan): Promise<SocialResponse[] | undefined> {
    try {
      const existingSocials = await trpc.social.get.query({ id: fan.id })
      return existingSocials
    } catch (error) {
      showWarning("Failed to fetch existing socials", error)
    }
  }

  async function updatePrimarySocial(social: SocialResponse) {
    try {
      analytics.primary({ social })

      const primarySocial = await trpc.fan.update.mutate({ primarySocialId: social.id })
      return primarySocial
    } catch (error) {
      showWarning("Failed to update primary social", error)
    }
  }

  return { fetchExistingSocials, updatePrimarySocial }
}
