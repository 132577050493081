import { base, baseSepolia } from "wagmi/chains"

// Chains
type Chain = typeof base | typeof baseSepolia
export const chains: Record<string, Chain> = {
  base,
  baseSepolia,
}
export const PRIMARY_CHAIN = chains[import.meta.env.VITE_PRIMARY_CHAIN as keyof typeof chains]

export const SLIPPAGE = 5n

// Explorers
const blockExplorers: Record<string, string> = {
  base: "https://basescan.org",
  baseGoerli: "https://goerli.basescan.org",
  baseSepolia: "https://sepolia.basescan.org",
}

export const BLOCK_EXPLORER_URL = blockExplorers[import.meta.env.VITE_PRIMARY_CHAIN as string]
export const WALLET_EXPLORER_URL = "https://rainbow.me"

// Platforms
export enum Platform {
  Base,
  ENS,
  Farcaster,
  // Twitter,
  // Facebook,
  // Instagram,
}

export const BASE_COLORS = ["#6194FE", "#9FBEFE", "#0B59FF", "#EFF4FE", "#2168FE"]
export const ENS_COLORS = ["#76D0FA", "#CFC0FC", "#81AAFD", "#A8FAF8", "#A789FF"]
export const FARCASTER_COLORS = ["#8465CB", "#E0D9F9", "#6E4DB8", "#F5F3FC", "#8465CB"]
// export const TWITTER_COLORS = ["#000000", "#C0E9FD", "#0E71B8", "#EFF8FE", "#1DA1F2"]
// export const FACEBOOK_COLORS = ["#3273F2", "#C0E9FD", "#0E71B8", "#EFF8FE", "#3B5998"]
// export const INSTAGRAM_COLORS = ["#E231BE", "#F8C7E9", "#C13584", "#FDEAF4", "#E231BE"]

export const PLATFORM_COLORS = {
  [Platform.Base]: BASE_COLORS,
  [Platform.ENS]: ENS_COLORS,
  [Platform.Farcaster]: FARCASTER_COLORS,
  // [Platform.Twitter]: TWITTER_COLORS,
  // [Platform.Facebook]: FACEBOOK_COLORS,
  // [Platform.Instagram]: INSTAGRAM_COLORS,
}

export const CHECKMARK_COLOR = [
  "#6194FE", // Default
  "#76D0FA", // ENS
  "#8465CB", // Farcaster
  // "#000000", // Twitter
  // "#3273F2", // Facebook
  // "#E231BE", // Instagram
]

// Links
export const CONTACT_EMAIL = "mailto:support@bracket.game"
export const DISCORD_URL = "https://discord.gg/FgWpX826Bb"
export const DOCS_URL = "https://nilli.gitbook.io/docs"
export const FARCASTER_URL = "https://warpcast.com/~/channel/bracket"
export const TWITTER_URL = "https://twitter.com/bracketHQ"
export const PRIVACY_URL = "https://nilli.com/privacy"
export const TERMS_URL = "https://nilli.com/tos"
