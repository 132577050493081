import { ContractResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

function findCachedContract(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.get, {}, "query")
  const contract = (queryClient.getQueryData(queryKey) as ContractResponse[])?.find((c) => c.id === id)
  return contract ? [contract] : undefined
}

export async function fetchInitialContracts(queryClient: QueryClient) {
  const queryKey = getQueryKey(trpc.contract.get, {}, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.contract.get.query({}),
  })
}

export function useContracts(id?: string, initialData?: ContractResponse[]): { data?: ContractResponse[] } {
  const queryClient = useQueryClient()
  return trpc.contract.get.useQuery(
    { id },
    {
      initialData: initialData ?? (() => findCachedContract(queryClient, id)),
    },
  )
}
