import { PostHogProvider } from "posthog-js/react"
import { PostHogConfig } from "posthog-js"

interface AnalyticsProviderProps {
  posthogHost: string
  posthogKey: string
  children: React.ReactNode
}

export function AnalyticsProvider({ posthogHost, posthogKey, children }: AnalyticsProviderProps) {
  const options: Partial<PostHogConfig> = {
    api_host: posthogHost,
    capture_pageview: false, // We capture pageviews manually in Layout.tsx
  }

  // Disable PostHog in local development to not pollute network requests
  if (import.meta.env.MODE !== "production") {
    return <>{children}</>
  }

  return (
    <PostHogProvider apiKey={posthogKey} options={options}>
      {children}
    </PostHogProvider>
  )
}
