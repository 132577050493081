import { useToast } from "@bracket/hooks/useToast"
import { LoginContext } from "@bracket/providers/LoginProvider"
import { useAppStore } from "@bracket/stores"
import { useFanStore } from "@bracket/stores/fan"
import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { usePostHog } from "posthog-js/react"
import { useContext } from "react"

export function useLoginContext() {
  const posthog = usePostHog()
  const reset = useFanStore((s) => s.reset)
  const resetHomePageTab = useAppStore((s) => s.resetHomePageTab)
  const { isAuthenticated, setShowAuthFlow, handleLogOut } = useDynamicContext()
  const { showError } = useToast()

  const context = useContext(LoginContext)
  if (context === null) {
    throw new Error("useLoginContext() was used outside of LoginProvider")
  }

  function login() {
    // TODO: we should do a better job of auto-pulling the fan information
    if (isAuthenticated) {
      logout()
      showError("Error: Please try logging in again")
    } else {
      setShowAuthFlow(true)
    }
  }

  function logout() {
    reset()
    handleLogOut()
    resetHomePageTab()

    posthog.reset()
    Sentry.setUser(null)
  }

  return {
    login,
    logout,
    isLoggedIn: context.isLoggedIn,
  }
}
