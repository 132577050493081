import LogotypeSplit from "@bracket/assets/logotype-split.svg?react"
import Menu from "@bracket/assets/menu.svg?react"
import { Avatar } from "@bracket/components/Avatar"
import { Marquee } from "@bracket/components/Marquee"
import { Button } from "@bracket/components/ui/button"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useLoginContext } from "@bracket/hooks/useLoginContext"
import { useTokenBalance } from "@bracket/hooks/useTokenBalance"
import { formatPrice } from "@bracket/lib/formatters"
import { useAppStore } from "@bracket/stores"
import { useFanStore } from "@bracket/stores/fan"
import { Modal } from "@bracket/types"
import { Link, useLocation } from "react-router-dom"

const events = {
  profile: "header:profile_button_click",
  login: "header:login_button_click",
  menu: "header:menu_button_click",
}

export function Header() {
  const analytics = useAnalytics(events)
  const openModal = useAppStore((s) => s.openModal)
  const primarySocial = useFanStore((s) => s.primarySocial)

  const { isLoggedIn, login } = useLoginContext()
  const location = useLocation()
  const { balance } = useTokenBalance()

  function handleClickProfile() {
    analytics.profile()
    openModal(Modal.Profile)
  }

  function handleClickLogin() {
    analytics.login()
    login()
  }

  function handleClickMenu() {
    analytics.menu()
    openModal(Modal.Nav)
  }

  return (
    <header className="flex flex-col items-center">
      <Marquee />

      <div className="mt-[.8rem] flex w-full px-4">
        <Link to="/" onClick={() => location.pathname === "/" && openModal(Modal.Nav)} className="rounded-md">
          <LogotypeSplit className="-ml-[.1rem] h-auto w-[9.25rem] transition-transform duration-75 active:scale-[98.5%]" />
        </Link>

        {/* Logged in state */}
        {isLoggedIn && (
          <Button
            variant="outline"
            onClick={handleClickProfile}
            className="ml-auto flex rounded-full border-border-dark px-1.5"
          >
            <Avatar src={primarySocial?.imageSrc} alt={`Avatar of ${primarySocial?.username}`} className="size-7" />
            <span className="ml-1 h-8 w-[1px] bg-border-gray" />
            <span className="ml-2 mr-[.42rem] font-medium text-buy-blue">{formatPrice(balance)}</span>
          </Button>
        )}

        {/* Logged out state */}
        {!isLoggedIn && (
          <Button
            onClick={handleClickLogin}
            className="ml-auto rounded-full px-6 text-[.9375rem] font-semibold hover:brightness-105"
          >
            Log in
          </Button>
        )}

        <Button size="icon" variant="disappear" tabIndex={-1} onClick={handleClickMenu}>
          <Menu className="ml-3 h-6 w-auto" />
        </Button>
      </div>
    </header>
  )
}
