import type { Fan } from "@backend/types"
import { ClaimButton } from "@bracket/components/buttons/ClaimButton"
import { PackButton } from "@bracket/components/buttons/PackButton"
import { RedeemButton } from "@bracket/components/buttons/RedeemButton"
import { ShareButton } from "@bracket/components/buttons/ShareButton"
import { useFanMutations } from "@bracket/hooks/mutations/useFanMutations"
import { useClaims } from "@bracket/hooks/queries/useClaims"
import { useCollectives } from "@bracket/hooks/queries/useCollectives"
import { useContracts } from "@bracket/hooks/queries/useContracts"
import { useHoldings } from "@bracket/hooks/queries/useHoldings"
import { usePack } from "@bracket/hooks/queries/usePack"
import { usePrices } from "@bracket/hooks/queries/usePrices"
import { useLoginContext } from "@bracket/hooks/useLoginContext"
import { usePWAMode } from "@bracket/hooks/usePWAMode"
import { usePricedCollectives } from "@bracket/hooks/usePricedCollectives"
import { cn } from "@bracket/lib/utils"
import { useAppStore } from "@bracket/stores"
import { useFanStore } from "@bracket/stores/fan"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export function ButtonController() {
  const isPWA = usePWAMode()
  const { slug } = useParams()
  const { fan, setFan } = useFanStore((s) => ({ fan: s.fan, setFan: s.setFan }))
  const activeContract = useAppStore((s) => s.activeContract)
  const { isLoggedIn } = useLoginContext()
  const { fetchExistingFan } = useFanMutations()

  // Claim button
  const { data: claims = [], refetch } = useClaims()

  // Redeem button
  const { data: [collective] = [] } = useCollectives(undefined, slug)
  const { data: [holding] = [] } = useHoldings(collective?.contract, collective?.slug, fan?.address)
  const { data: [contract] = [] } = useContracts(collective?.contract)
  const { data: [price] = [] } = usePrices({ contract: collective?.contract, slug: collective?.slug })
  const [pricedCollective] = usePricedCollectives([collective], [price])

  // Share button
  const { data: [sharePack] = [] } = usePack(fan?.shareCode ?? undefined)

  // Pack button
  const { data: [packContract] = [] } = useContracts(activeContract)

  // Conditions
  const forceShowRedeemButton = useFeatureFlagEnabled("ui_show_redeem_button")
  const canRedeem =
    isLoggedIn &&
    (forceShowRedeemButton || contract?.isDistributed) &&
    holding?.fanVotes > 0 &&
    collective?.winningPct > 0 &&
    !!slug
  const hasClaims = claims.length > 0 && !slug
  const hasInvites = sharePack?.balance > 0 && !slug
  const isSelling = packContract?.packCodes.length > 0 && !slug && !hasClaims
  const isHidden = !isLoggedIn || (!canRedeem && !hasClaims) // && !isSelling)

  // Refetch existing fan information
  useEffect(() => {
    async function fetchShareCode() {
      const { shareCode } = (await fetchExistingFan()) as Fan
      if (fan?.id && shareCode) setFan({ ...fan, shareCode })
    }
    if (!isLoggedIn) return
    fetchShareCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  // Fetch claims when the user is logged in
  useEffect(() => {
    if (!isLoggedIn) return
    refetch()
  }, [isLoggedIn, refetch])

  return (
    <div
      className={cn(
        "fixed bottom-0 z-20 flex h-fit w-full max-w-md flex-col items-center bg-gradient-to-t from-white via-white via-80% to-transparent px-4 py-[1.11rem]",
        { "hidden ": isHidden },
        { "mb-20": isPWA && !slug },
      )}
    >
      <div className="flex w-full items-center space-x-2">
        {hasInvites && <ShareButton pack={sharePack} />}
        {canRedeem && <RedeemButton collective={pricedCollective} />}
        {hasClaims && <ClaimButton claims={claims} />}
        {isSelling && <PackButton />}
      </div>
    </div>
  )
}
