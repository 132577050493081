import type { HoldingResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

export async function fetchInitialHoldings(
  queryClient: QueryClient,
  address: string,
  contract?: string,
  slug?: string,
) {
  const queryKey = getQueryKey(trpc.trade.getHoldings, { contract, slug, address }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.trade.getHoldings.query({ contract, slug, address }),
  })
}

export function useHoldings(
  contract?: string,
  slug?: string,
  address?: string | null,
  initialData?: HoldingResponse[],
) {
  return trpc.trade.getHoldings.useQuery(
    { contract, slug, address: address ?? "" },
    {
      enabled: !!address,
      refetchInterval: 5_000, // Default polling interval
      ...(initialData && { initialData }),
    },
  )
}

export function useHoldingsSuspense(
  address: string,
  contract?: string,
  slug?: string,
  initialData?: HoldingResponse[],
) {
  return trpc.trade.getHoldings.useSuspenseQuery(
    { contract, slug, address },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData: () => {
        if (initialData && contract && initialData[0]?.contract === contract) return initialData
      },
    },
  )
}
