import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import * as React from "react"

import { cn } from "@bracket/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap transition rounded-lg text-sm font-medium ring-offset-white disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-buy-blue hover:bg-bracket-blue/85 active:bg-bracket-blue/95 text-white active:text-opacity-100",
        destructive: "bg-sell-red hover:bg-sell-red/90 active:bg-sell-red text-white active:text-opacity-90",
        outline: "border border-border-dark bg-white hover:bg-[#f8f8f8]",
        secondary: "bg-zinc-100 text-zinc-900 hover:bg-zinc-100/80",
        ghost: "hover:bg-buy-blue/5 hover:text-buy-blue",
        link: "text-buy-blue underline-offset-4 hover:underline",
        // Custom
        loading: "bg-gray-200 text-gray-900",
        disappear: "bg-transparent transition-all hover:bg-transparent active:opacity-50",
      },
      size: {
        default: "h-[2.45rem] px-4 py-2",
        sm: "h-9 px-3",
        lg: "h-[3.43rem] px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
