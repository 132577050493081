import type { CollectiveResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { useFanStore } from "@bracket/stores/fan"

function findCachedCollective(queryClient: QueryClient, contract?: string, slug?: string) {
  const address = useFanStore.getState().fan?.address
  const queryKey = getQueryKey(trpc.collective.get, { contract, ...(address && { address }) }, "query")
  const collective = (queryClient.getQueryData(queryKey) as CollectiveResponse[])?.find((c) => c.slug === slug)
  return collective ? [collective] : undefined
}

export async function fetchInitialCollectives(queryClient: QueryClient, contract?: string, slug?: string) {
  const address = useFanStore.getState().fan?.address
  const queryKey = getQueryKey(trpc.collective.get, { contract, slug, ...(address && { address }) }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.collective.get.query({ contract, slug }),
  })
}

export function useCollectives(contract?: string, slug?: string, initialData?: CollectiveResponse[]) {
  const address = useFanStore.getState().fan?.address
  const queryClient = useQueryClient()
  return trpc.collective.get.useQuery(
    { contract, slug, ...(address && { address }) },
    {
      staleTime: Infinity, // Do not refetch collective info repeatedly
      initialData: () => {
        if (initialData && contract && initialData[0].contract === contract) return initialData
        else return findCachedCollective(queryClient, contract, slug)
      },
    },
  )
}

export function useCollectivesSuspense(contract?: string, slug?: string, initialData?: CollectiveResponse[]) {
  const address = useFanStore.getState().fan?.address
  const queryClient = useQueryClient()
  return trpc.collective.get.useSuspenseQuery(
    { contract, slug, ...(address && { address }) },
    {
      staleTime: Infinity, // Do not refetch collective info repeatedly
      initialData: () => {
        if (initialData && contract && initialData[0].contract === contract) return initialData
        else return findCachedCollective(queryClient, contract, slug)
      },
    },
  )
}
