import { Fan } from "@backend/types"
import { useFanMutations } from "@bracket/hooks/mutations/useFanMutations"
import { useSocialMutations } from "@bracket/hooks/mutations/useSocialMutations"
import { useTokenBalance } from "@bracket/hooks/useTokenBalance"
import { useAppStore } from "@bracket/stores"
import { useFanStore } from "@bracket/stores/fan"
import { Modal } from "@bracket/types"
import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { usePostHog } from "posthog-js/react"
import { createContext, useCallback, useEffect } from "react"

interface LoginContext {
  isLoggedIn: boolean
}

export const LoginContext = createContext<LoginContext | null>(null)

interface LoginProviderProps {
  children: React.ReactNode
}

export function LoginProvider(props: LoginProviderProps) {
  const openModal = useAppStore((s) => s.openModal)
  const { fan, primarySocial } = useFanStore((s) => ({
    fan: s.fan,
    primarySocial: s.primarySocial,
  }))
  const { hasSeenOnboardModal, setHasSeenOnboardModal, activeModal } = useAppStore((s) => ({
    hasSeenOnboardModal: s.hasSeenOnboardModal,
    setHasSeenOnboardModal: s.setHasSeenOnboardModal,
    activeModal: s.activeModal,
  }))

  const { fetchExistingFan } = useFanMutations()
  const { fetchExistingSocials } = useSocialMutations()
  const { isAuthenticated, primaryWallet } = useDynamicContext()
  const { balance } = useTokenBalance()
  const posthog = usePostHog()

  const isLoggedIn = (isAuthenticated && !!fan && !!primarySocial) ?? false

  const checkForExistingUser = useCallback(async () => {
    const existingFan = (await fetchExistingFan()) as Fan
    if (existingFan) {
      fetchExistingSocials(existingFan)
      posthog.capture("login:session_restore", { ...existingFan })
    }
  }, [fetchExistingFan, fetchExistingSocials, posthog])

  // Identify user for error tracking
  useEffect(() => {
    if (!isLoggedIn || !fan?.id || !fan?.address) return
    posthog.identify(fan.address, { ...fan })
    Sentry.setUser({ id: fan.id, email: fan.email, address: fan.address })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, fan?.id, fan?.address])

  // Show onboard modal to the fan on first visit
  useEffect(() => {
    if (!isLoggedIn || hasSeenOnboardModal || balance > 0 || activeModal === Modal.Claim) return
    openModal(Modal.Onboard)
    setHasSeenOnboardModal(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  // Fallback to restore existing fan sessions
  useEffect(() => {
    if (isLoggedIn || !primaryWallet?.address) return
    checkForExistingUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryWallet?.address])

  const context = { isLoggedIn }
  return <LoginContext.Provider value={context}>{props.children}</LoginContext.Provider>
}
