import {
  CollectiveResponse,
  ContractResponse,
  ContractUIConfig,
  HoldingResponse,
  PriceResponse,
  SparklineResponse,
  StatsResponse,
} from "@backend/types"
import { fetchInitialCollectives } from "@bracket/hooks/queries/useCollectives"
import { fetchInitialContracts } from "@bracket/hooks/queries/useContracts"
import { fetchInitialHoldings } from "@bracket/hooks/queries/useHoldings"
import { fetchInitialPrices } from "@bracket/hooks/queries/usePrices"
import { fetchInitialUIConfigs } from "@bracket/hooks/queries/useUIConfigs"
import { useAppStore } from "@bracket/stores"
import { useUIConfigStore } from "@bracket/stores/contract"
import { useFanStore } from "@bracket/stores/fan"
import { Modal } from "@bracket/types"
import { QueryClient } from "@tanstack/react-query"
import { LoaderFunctionArgs } from "react-router-dom"

export interface HomeLoaderData {
  initialContracts: ContractResponse[]
  initialUIConfigs: ContractUIConfig[]
  initialCollectives: CollectiveResponse[]
  initialPrices: PriceResponse[]
  initialSparklines?: SparklineResponse[]
  initialStats?: StatsResponse
  initialHoldings?: HoldingResponse[]
}

export function homeLoader(queryClient: QueryClient, modal?: Modal) {
  return async ({ request }: LoaderFunctionArgs): Promise<HomeLoaderData> => {
    const url = new URL(request.url)
    const ref = url.searchParams.get("ref")
    const collective = url.searchParams.get("collective")
    const activeModal = useAppStore.getState().activeModal

    // Open modal based on path
    if (modal && activeModal !== Modal.TrusteeForm) {
      useAppStore.getState().setModalState({ ...(collective ? {} : { default: true }) })
      useAppStore.getState().openModal(modal)
    } else if (ref) {
      useAppStore.getState().openModal(Modal.Referral)
    }

    const initialContracts = await fetchInitialContracts(queryClient)
    const initialUIConfigs = await fetchInitialUIConfigs(queryClient)
    useUIConfigStore.getState().set(initialUIConfigs)
    const activeContract = useAppStore.getState().activeContract ?? "nba"

    const initialCollectives = await fetchInitialCollectives(queryClient, activeContract)
    const initialPrices = await fetchInitialPrices(queryClient, activeContract)
    // const initialSparklines = await fetchInitialSparklines(queryClient, activeContract)

    const fan = useFanStore.getState().fan
    if (!fan?.address) return { initialContracts, initialUIConfigs, initialCollectives, initialPrices }

    const initialHoldings = await fetchInitialHoldings(queryClient, fan.address, activeContract)
    return { initialContracts, initialUIConfigs, initialCollectives, initialPrices, initialHoldings }
  }
}

interface CollectiveLoaderArgs extends LoaderFunctionArgs {
  params: { slug: string }
}

export interface CollectiveLoaderData {
  initialCollective: CollectiveResponse
  initialPrice: PriceResponse
  initialHolding?: HoldingResponse
  initialContracts: ContractResponse[]
}

export function collectiveLoader(queryClient: QueryClient) {
  return async ({ params }: CollectiveLoaderArgs): Promise<CollectiveLoaderData> => {
    const { slug } = params
    const [initialCollective] = await fetchInitialCollectives(queryClient, undefined, slug)
    const [initialPrice] = await fetchInitialPrices(queryClient, undefined, slug)
    const initialContracts = await fetchInitialContracts(queryClient)

    const fan = useFanStore.getState().fan
    if (!fan?.address) return { initialCollective, initialPrice, initialContracts }

    const [initialHolding] = await fetchInitialHoldings(queryClient, fan.address, undefined, slug)
    return { initialCollective, initialPrice, initialHolding, initialContracts }
  }
}
