import DegenCoins from "@bracket/assets/degen-coins.svg?react"
import { Button } from "@bracket/components/ui/button"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useAppStore } from "@bracket/stores"
import { Modal, PricedCollective } from "@bracket/types"
import { ChevronRight } from "lucide-react"

interface RedeemButtonProps {
  collective: PricedCollective
}

const events = {
  redeem: "redeem_button:redeem_button_click",
}

export function RedeemButton({ collective }: RedeemButtonProps) {
  const analytics = useAnalytics(events)
  const { openModal, setActiveCollective } = useAppStore((s) => ({
    openModal: s.openModal,
    setActiveCollective: s.setActiveCollective,
  }))

  function handleClickRedeem() {
    analytics.redeem()
    setActiveCollective(collective)
    openModal(Modal.Redeem)
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickRedeem}
      className="relative flex h-14 w-full items-center justify-center bg-purple-prize text-lg font-semibold transition-all hover:bg-[#814be6] active:bg-[#7941df]"
    >
      <DegenCoins className="mb-[.33rem] mr-[.45rem] scale-[1.0]" />
      Redeem to collect <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
    </Button>
  )
}
