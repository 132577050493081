import { Header } from "@bracket/components/Header"
import { ButtonController } from "@bracket/components/buttons/ButtonController"
import { Toaster } from "@bracket/components/ui/sonner"
import { usePWAMode } from "@bracket/hooks/usePWAMode"
import { usePrimaryChainSwitch } from "@bracket/hooks/usePrimaryChainSwitch"
import { usePostHog } from "posthog-js/react"
import { lazy, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

interface LayoutProps {
  children?: React.ReactNode
}

const ModalController = lazy(() => import("./modals/ModalController"))

export function Layout(props: LayoutProps) {
  const location = useLocation()
  const posthog = usePostHog()
  const isPWA = usePWAMode()

  // Ensure user is connected to correct chain
  usePrimaryChainSwitch()

  // TODO: Move pageview tracking to better location
  useEffect(() => {
    posthog.capture("$pageview")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Hide splash screen once app is ready
  useEffect(() => {
    const splash = document.getElementById("splash")
    if (splash) {
      splash.style.display = "none"
    }

    const themeColorMeta = document.querySelector('meta[name="theme-color"]')
    if (themeColorMeta && isPWA) {
      themeColorMeta.setAttribute("content", "#000000")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      {...(isPWA && { "vaul-drawer-wrapper": "" })}
      className="mx-auto flex min-h-[100dvh] max-w-md flex-col border-[#ebf1f4] bg-white shadow-md"
    >
      <Header />

      <main className="flex grow flex-col">
        {props.children}
        <Outlet />
      </main>

      <ButtonController />
      <ModalController />
      <Toaster position={isPWA ? "top-center" : "bottom-center"} />
    </div>
  )
}
