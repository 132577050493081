import { PackResponse } from "@backend/types"
import { Button } from "@bracket/components/ui/button"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { sharePage } from "@bracket/lib/utils"
import { useAppStore } from "@bracket/stores"
import { Modal } from "@bracket/types"

interface ShareButtonProps {
  pack: PackResponse
}

const events = {
  share: "share_button:share_button_click",
}

export function ShareButton({ pack }: ShareButtonProps) {
  const analytics = useAnalytics(events)
  const openModal = useAppStore((s) => s.openModal)

  function handleClickShare() {
    analytics.share()

    if (!pack) {
      sharePage()
      return
    } else if (pack.balance === 0) {
      sharePage(undefined, undefined, `https://bracket.game/?ref=${pack.code}`)
      return
    }

    openModal(Modal.Share)
  }

  return (
    <Button
      size="lg"
      variant="outline"
      onClick={handleClickShare}
      className="rounded-full border-2 border-buy-blue px-6 text-lg font-semibold text-buy-blue transition-all"
    >
      {pack?.balance > 0 ? "Invite" : "Share"}
    </Button>
  )
}
