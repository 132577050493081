import * as R from "remeda"
import type { Config } from "unique-names-generator"
import { NumberDictionary, adjectives, animals, uniqueNamesGenerator } from "unique-names-generator"
import { formatUnits, parseUnits } from "viem"

export { R }

const numberDictionary = NumberDictionary.generate({ min: 100, max: 999 })
const customConfig: Config = {
  dictionaries: [adjectives, animals, numberDictionary],
  separator: "-",
  length: 3,
}

export function getRandomUsername() {
  return uniqueNamesGenerator(customConfig)
}
export function normTokenAmount<T extends Record<string, bigint>>(amount: T, decimals: number): Record<string, number>
export function normTokenAmount<T extends bigint>(amount: T, decimals: number): number
export function normTokenAmount<T extends Record<string, bigint> | bigint>(
  amount: T,
  decimals: number,
): number | Record<string, number> {
  const norm = (v: bigint) => parseFloat(formatUnits(v, decimals))

  if (typeof amount === "object") {
    return Object.fromEntries(Object.entries(amount).map(([key, value]) => [key, norm(value)]))
  }

  return norm(amount)
}

export function denormTokenAmount<T extends Record<string, number>>(amount: T, decimals: number): Record<string, bigint>
export function denormTokenAmount<T extends number>(amount: T, decimals: number): bigint
export function denormTokenAmount<T extends number | Record<string, number>>(
  amount: T,
  decimals: number,
): bigint | Record<string, bigint> {
  const denorm = (v: number) => parseUnits(v.toString(), decimals)

  if (typeof amount === "object") {
    return Object.fromEntries(Object.entries(amount).map(([key, value]) => [key, denorm(value)]))
  }

  return denorm(amount)
}

export function shorten(address: string | null | undefined, length = 4): string {
  if (!address) return ""
  if (address.length < 32) return address
  return `${address.slice(0, 6)}...${address.slice(-length)}`.toLowerCase()
}
