import { useAppStore } from "@bracket/stores"
import { useContracts } from "@bracket/hooks/queries/useContracts"
import { useMemo } from "react"
import { Address } from "viem"

export function useContractToken() {
  const activeContract = useAppStore((s) => s.activeContract)
  const { data: [contract] = [] } = useContracts(activeContract)

  return useMemo(
    () => ({
      address: contract?.tokenAddress as Address,
      symbol: contract?.tokenSymbol,
      decimals: contract?.tokenDecimals,
    }),
    [contract],
  )
}
