import { ClaimResponse } from "@backend/types"
import { Button } from "@bracket/components/ui/button"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useAppStore } from "@bracket/stores"
import { Modal } from "@bracket/types"
import { ChevronRight } from "lucide-react"

interface ClaimButtonProps {
  claims: ClaimResponse[]
}

const events = {
  redeem: "claim_button:claim_button_click",
}

export function ClaimButton({ claims }: ClaimButtonProps) {
  const analytics = useAnalytics(events)
  const openModal = useAppStore((s) => s.openModal)

  function handleClickClaim() {
    analytics.redeem()
    openModal(Modal.Claim)
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickClaim}
      className="relative flex h-14 w-full items-center justify-center bg-black text-lg font-semibold hover:bg-black/85 active:bg-black/95"
    >
      <span className="mb-0.5">
        {claims.length} pack{claims.length > 1 ? "s" : ""} to open
      </span>
      <ChevronRight className="absolute right-3 text-white" />
    </Button>
  )
}
