import type { PriceResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import type { CurrencyTimeIntervals } from "@backend/services/CurrencyService"

function findCachedPrice(queryClient: QueryClient, contract?: string, slug?: string) {
  const queryKey = getQueryKey(trpc.collective.getPrices, { contract }, "query")
  const price = (queryClient.getQueryData(queryKey) as PriceResponse[])?.find((p) => p.slug === slug)
  return price ? [price] : undefined
}

export async function fetchInitialPrices(queryClient: QueryClient, contract?: string, slug?: string) {
  const queryKey = getQueryKey(trpc.collective.getPrices, { contract, slug }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.collective.getPrices.query({ contract, slug }),
  })
}

export function usePrices(
  {
    contract,
    slug,
    interval,
    fromMoment,
  }: {
    contract?: string
    slug?: string
    interval?: CurrencyTimeIntervals
    fromMoment?: "now" | "lastTrade"
  } = {},
  initialData?: PriceResponse[],
): { data: PriceResponse[] | undefined; isLoading: boolean } {
  const queryClient = useQueryClient()
  return trpc.collective.getPrices.useQuery(
    { contract, slug, interval, fromMoment },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData: () => {
        if (initialData && contract && initialData[0].contract === contract) return initialData
        else return findCachedPrice(queryClient, contract, slug)
      },
    },
  )
}

export function usePricesSuspense(contract?: string, slug?: string, initialData?: PriceResponse[]) {
  const queryClient = useQueryClient()
  return trpc.collective.getPrices.useSuspenseQuery(
    { contract, slug },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData: () => {
        if (initialData && contract && initialData[0].contract === contract) return initialData
        else return findCachedPrice(queryClient, contract, slug)
      },
    },
  )
}
