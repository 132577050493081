import { superjsonStorage, withDevLog } from "@bracket/stores/utils"
import { Modal, PricedCollective } from "@bracket/types"
import { uuidv7 } from "posthog-js/lib/src/uuidv7"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

// Contract
interface ContractState {
  activeContract: string
}

interface ContractStateActions {
  setActiveContract: (activeContract: string) => void
}

const initialContractState: ContractState = {
  activeContract: "farcon",
}

// Collective
interface CollectiveState {
  activeCollective?: PricedCollective
}

interface CollectiveStateActions {
  setActiveCollective: (activeCollective?: PricedCollective) => void
}

const initialCollectiveState: CollectiveState = {
  activeCollective: undefined,
}

// Modal
// TODO: should these be state or url params?
type ModalDetails = {
  tab?: string
  isBuy?: boolean
  default?: boolean
  fanAddress?: string | null
  token?: "ETH" | "DEGEN" | "USDC" | "CHZ"
  tokenUrl?: string
  price?: number
}

interface ModalState {
  activeModal: Modal
  activeModalId?: string
  modalState: ModalDetails
  trusteeReason: string
}

interface ModalStateActions {
  openModal: (modal: Modal, state?: Record<string, unknown>) => void
  closeModal: (open: boolean) => void
  setModalState: (modalState: ModalDetails) => void
  setTrusteeReason: (reason: string) => void
}

const initialModalState: ModalState = {
  activeModal: Modal.None,
  activeModalId: undefined,
  modalState: {},
  trusteeReason: "",
}

// Referral
interface ReferralState {
  referralId?: string
}

interface ReferralStateActions {
  setReferralId: (referralId: string | undefined) => void
}

const initialReferralState: ReferralState = {
  referralId: undefined,
}

// General
export type Currency = "DEGEN" | "USDC" | "USBG"
export type HomePageTab = "holdings" | "active" | "trustees"

interface GeneralSettingsState {
  currency: Currency
  homePageTab: HomePageTab
  hasSeenOnboardModal: boolean
}

interface GeneralSettingsStateActions {
  toggleCurrency: () => void
  setHomePageTab: (tab: HomePageTab) => void
  resetHomePageTab: () => void
  setHasSeenOnboardModal: (hasSeenOnboardModal: boolean) => void
}

const initialGeneralSettingsState: GeneralSettingsState = {
  currency: "USDC",
  homePageTab: "active",
  hasSeenOnboardModal: false,
}

type State = ContractState & CollectiveState & ModalState & ReferralState & GeneralSettingsState

type Actions = ContractStateActions &
  CollectiveStateActions &
  ModalStateActions &
  ReferralStateActions &
  GeneralSettingsStateActions & {
    reset: () => void
  }

const initialState: State = {
  ...initialContractState,
  ...initialCollectiveState,
  ...initialModalState,
  ...initialReferralState,
  ...initialGeneralSettingsState,
}

export const useAppStore = create<State & Actions>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialState,

        // Contract
        setActiveContract: (activeContract) => set({ activeContract }),

        // Collectives
        setActiveCollective: (activeCollective) => set({ activeCollective }),

        // Modal
        openModal: (activeModal) => set({ activeModal, activeModalId: uuidv7() }),
        closeModal: (open) => {
          if (open) return
          set({ activeModal: Modal.None, activeModalId: undefined })
          setTimeout(() => set({ modalState: {} }), 280) // TODO: remove?
        },
        setModalState: (modalState) => set({ modalState }),
        setTrusteeReason: (trusteeReason) => set({ trusteeReason }),

        // Referral
        setReferralId: (referralId) => set({ referralId }),

        // General Settings
        toggleCurrency: () =>
          set((state) => ({
            currency: state.currency === "DEGEN" ? "USDC" : "DEGEN",
          })),
        setHomePageTab: (tab) => set({ homePageTab: tab }),
        resetHomePageTab: () => set({ homePageTab: initialState.homePageTab }),
        setHasSeenOnboardModal: (hasSeenOnboardModal) => set({ hasSeenOnboardModal }),

        // Misc
        reset: () => set(initialState),
      })),
      {
        name: "bracket.store",
        version: 6, // Version last updated: 2024-04-10
        storage: superjsonStorage(),
        // exclude modal state from persisting
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) =>
                !Object.keys(initialModalState).includes(key) && !Object.keys(initialReferralState).includes(key),
            ),
          ),
      },
    ),
  ),
)

if (import.meta.env.DEV) {
  withDevLog(useAppStore, "appStore")
}
