import { Button } from "@bracket/components/ui/button"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useLoginContext } from "@bracket/hooks/useLoginContext"
import { useAppStore } from "@bracket/stores"
import { Modal } from "@bracket/types"
import { ChevronRight } from "lucide-react"

const events = {
  purchase: "pack_button:pack_button_click",
}

export function PackButton() {
  const analytics = useAnalytics(events)
  const openModal = useAppStore((s) => s.openModal)
  const { isLoggedIn, login } = useLoginContext()

  function handleClickPurchase() {
    analytics.purchase()

    if (!isLoggedIn) {
      login()
      return
    }

    openModal(Modal.Pack)
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickPurchase}
      className="relative flex h-14 w-full grow items-center justify-center bg-buy-blue text-lg font-semibold transition-all"
    >
      <span className="mb-0.5">Get in with ETH</span>
      <ChevronRight className="absolute right-3 text-white" />
    </Button>
  )
}
