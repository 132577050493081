import type { StatsResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

export async function fetchInitialStats(queryClient: QueryClient, contract?: string) {
  const queryKey = getQueryKey(trpc.trade.getStats, { contract }, "query")
  return queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.trade.getStats.query({ contract }),
  })
}

export function useStats(contract?: string, initialData?: StatsResponse) {
  return trpc.trade.getStats.useQuery(contract ? { contract } : skipToken, {
    gcTime: 0,
    ...(initialData && { initialData }),
  })
}
