import type { ClaimResponse } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

export async function fetchInitialClaims(queryClient: QueryClient) {
  const queryKey = getQueryKey(trpc.claim.get, undefined, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.claim.get.query(),
  })
}

export function useClaims(initialData?: ClaimResponse[]) {
  return trpc.claim.get.useQuery(undefined, {
    enabled: false, // Do not refetch claims on mount
    staleTime: Infinity, // Do not refetch claims repeatedly
    ...(initialData && { initialData }),
  })
}
