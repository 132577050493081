import type { CollectiveResponse, HoldingResponse, PriceResponse, SparklineResponse } from "@backend/types"

export enum Modal {
  Claim,
  Deposit,
  Enter,
  Fan,
  Fund,
  GeneralFAQs,
  Nav,
  None,
  Offboard,
  Onboard,
  Pack,
  Profile,
  Redeem,
  Referral,
  Share,
  Social,
  Trade,
  TrusteeFAQs,
  TrusteeForm,
  Withdraw,
}

export interface PricedCollective extends CollectiveResponse, PriceResponse {}
export interface PricedHolding extends HoldingResponse, PriceResponse {}

export interface PricedCollectiveWithSparkline extends PricedCollective {
  sparkline: SparklineResponse["prices"]
}

export type ContractId = "ncaab" | "golf" | "nba" | "nfl" | "farcon"
