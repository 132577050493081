import { ContractUIConfig } from "@backend/types"
import { trpc, trpcClient } from "@bracket/lib/trpc"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"

function findCachedUIConfigs(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.getConfigs, {}, "query")
  const contract = (queryClient.getQueryData(queryKey) as ContractUIConfig[])?.find((c) => c.contractId === id)
  return contract ? [contract] : undefined
}

export async function fetchInitialUIConfigs(queryClient: QueryClient) {
  const queryKey = getQueryKey(trpc.contract.getConfigs, {}, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcClient.contract.getConfigs.query({}),
  })
}

export function useUIConfigs(id?: string, initialData?: ContractUIConfig[]) {
  const queryClient = useQueryClient()
  return trpc.contract.getConfigs.useQuery(
    { id },
    {
      initialData: initialData ?? (() => findCachedUIConfigs(queryClient, id)),
    },
  )
}
