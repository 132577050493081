import { Fan } from "@backend/types"
import { useFanMutations } from "@bracket/hooks/mutations/useFanMutations"
import { useSocialMutations } from "@bracket/hooks/mutations/useSocialMutations"
import { useAnalytics } from "@bracket/hooks/useAnalytics"
import { useFanStore } from "@bracket/stores/fan"
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum"
import { ZeroDevSmartWalletConnectorsWithConfig } from "@dynamic-labs/ethereum-aa"
import type { OnAuthSuccess, OnEmbeddedWalletCreated } from "@dynamic-labs/sdk-react-core"
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core"

const events = {
  authenticate: "dynamic:fan_authenticate",
  logout: "dynamic:fan_logout",
  link: "dynamic:wallet_link",
  unlink: "dynamic:wallet_unlink",
}

interface DynamicProviderProps {
  environmentId: string
  children: React.ReactNode
}

export function DynamicProvider({ environmentId, children }: DynamicProviderProps) {
  const analytics = useAnalytics(events)
  const { fan, setFan, setSocials, setPrimarySocial, reset } = useFanStore((s) => ({
    fan: s.fan,
    setFan: s.setFan,
    setSocials: s.setSocials,
    setPrimarySocial: s.setPrimarySocial,
    reset: s.reset,
  }))

  const { fetchExistingFan, createNewFan, updateExistingFan } = useFanMutations()
  const { fetchExistingSocials } = useSocialMutations()

  // TODO: move to login provider after migration
  async function updateStore(fan?: Fan) {
    if (!fan) return // TODO: early return for now

    setFan(fan)
    const existingSocials = await fetchExistingSocials(fan)
    if (existingSocials) {
      setSocials(existingSocials)
      const primarySocial = existingSocials.find((social) => social.id === fan.primarySocialId)
      if (primarySocial) setPrimarySocial(primarySocial)
    }
  }

  async function onAuthSuccess(args: Parameters<OnAuthSuccess>[0]) {
    const { user, primaryWallet } = args
    const { email, newUser, username, wallet } = user

    analytics.authenticate({
      address: primaryWallet?.address,
      email,
      newUser,
      username,
      wallet,
    })

    // New fan case
    if (newUser) {
      const newFan = await createNewFan(user)
      await updateStore(newFan)
    }

    // Existing fan case
    else if (primaryWallet?.address) {
      let existingFan = (await fetchExistingFan()) as Fan

      // Testnet user case
      if (!existingFan?.address) {
        existingFan = (await updateExistingFan()) as Fan
      }
      await updateStore(existingFan)
    }
  }

  async function onEmbeddedWalletCreated(args: Parameters<OnEmbeddedWalletCreated>[0]) {
    // Save wallet addresses to fan record
    if (!args?.address) return

    const newFan = await updateExistingFan()
    await updateStore(newFan)
  }

  function onLogout() {
    analytics.logout({
      ...fan,
    })

    reset()
  }

  return (
    <DynamicContextProvider
      settings={{
        environmentId,
        walletConnectors: [
          EthereumWalletConnectors,
          ZeroDevSmartWalletConnectorsWithConfig({ bundlerProvider: "ALCHEMY" }),
        ],
        eventsCallbacks: {
          onAuthSuccess,
          onEmbeddedWalletCreated,
          onLogout,
        },
        hideEmbeddedWalletTransactionUIs: true,
      }}
    >
      {children}
    </DynamicContextProvider>
  )
}
