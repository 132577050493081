import { withDevLog } from "@bracket/stores/utils"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"
import { ContractUIConfig } from "@backend/types"

import { ContractId } from "@bracket/types"

export interface UIConfigState {
  configs: Record<string, ContractUIConfig>
  activeContracts: { contractId: ContractId; contractDisplayName: string }[]
  currentFAQ: ContractId | undefined // property to explicitly set a general faq to render (when clicking on button in side nav)
}

export interface UIConfigActions {
  set: (contracts: ContractUIConfig[]) => void
  setCurrentFAQ: (contractId: ContractId | undefined) => void
}

const initialState: UIConfigState = {
  configs: {},
  activeContracts: [],
  currentFAQ: undefined,
}

export const useUIConfigStore = create<UIConfigState & UIConfigActions>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialState,
        set: (newConfigs) =>
          set((state) => {
            const newRecords: Record<string, ContractUIConfig> = {}
            const activeContracts: { contractId: string; contractDisplayName: string }[] = []
            newConfigs.sort((a, b) => Number(a.displayOrder) - Number(b.displayOrder))
            newConfigs.forEach((c) => {
              newRecords[c.contractId] = c
              activeContracts.push({
                contractId: c.contractId as ContractId,
                contractDisplayName: c.contractDisplayName,
              })
            })
            return {
              configs: {
                ...state.configs,
                ...newRecords,
              },
              activeContracts,
            }
          }),
        setCurrentFAQ: (contractId) => set({ currentFAQ: contractId }),
      })),
      { name: "bracket.uiConfig", version: 2 }, // Version last updated: 2024-04-10
    ),
  ),
)

if (import.meta.env.DEV) {
  withDevLog(useUIConfigStore, "UIConfigStore")
}
