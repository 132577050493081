import type { CollectiveResponse, PriceResponse } from "@backend/types"
import type { PricedCollective } from "@bracket/types"
import { useMemo } from "react"

const defaultPrice: PriceResponse = {
  burntVoteCount: 0,
  claimerVoteCount: 0,
  fanCount: 0,
  hadTrades: false,
  percent: 0,
  price: 0,
  slug: "",
  voteCount: 0,
  treasuryValue: 0,
  contract: "",
}

export function usePricedCollectives(
  collectives: CollectiveResponse[],
  prices: PriceResponse[],
  filterFn?: (collective: CollectiveResponse) => boolean,
): PricedCollective[] {
  return useMemo(() => {
    const pricesMap = new Map(prices.map((price) => [price?.slug, price]))
    const filteredCollectives = filterFn ? collectives.filter(filterFn) : collectives

    const pricedCollectives: PricedCollective[] = filteredCollectives.map((collective) => {
      const price = pricesMap.get(collective?.slug)
      return price ? { ...price, ...collective } : { ...defaultPrice, ...collective }
    })

    return pricedCollectives
  }, [collectives, prices, filterFn])
}
