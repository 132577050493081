import { clsx, type ClassValue } from "clsx"
import { toast } from "sonner"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function shorten(address: string | null | undefined, length = 4): string {
  if (!address) return ""
  if (address.length < 32) return address
  return `${address.slice(0, 6)}...${address.slice(-length)}`.toLowerCase()
}

export function sharePage(title = "", text = "", overrideUrl?: string) {
  const url = overrideUrl ?? window.location.href
  if (!url) return

  const data = { url, title, text }

  if (navigator.canShare && navigator.canShare(data)) {
    navigator.share(data)
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(url)
    toast.success("Link copied!")
  } else {
    toast.error("Something went wrong")
  }
}

export function removeLeadingZeros(input: string): string {
  return input.length > 1 ? input.replace(/^0+/, "") : input
}

export function getOrdinalSuffix(n: number): string {
  const s = ["th", "st", "nd", "rd"]
  const v = n % 100
  return s[(v - 20) % 10] || s[v] || s[0]
}
