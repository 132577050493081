import { AvatarFallback, AvatarImage, Avatar as AvatarRoot } from "@bracket/components/ui/avatar"
import { PLATFORM_COLORS, Platform } from "@bracket/lib/constants"
import { default as BoringAvatar } from "boring-avatars"
import { CSSProperties, useState } from "react"
import { cn } from "@bracket/lib/utils"

interface AvatarProps {
  src?: string | null
  alt: string
  acronym?: string
  primaryColor?: string | null
  secondaryColor?: string | null
  className?: string
  style?: Partial<CSSProperties>
  // Fixes the flickering when switching tabs by using <img /> tag instead of Radix's AvatarImage
  useImgTagFixFlickering?: boolean
}

export function Avatar(props: AvatarProps) {
  const [renderImgTag, setRenderImgTag] = useState(props.useImgTagFixFlickering)
  return (
    <AvatarRoot className={props.className} style={props?.style || {}}>
      {renderImgTag && (
        <img
          src={props.src ?? undefined}
          alt={props.alt}
          className={cn("object-cover object-center", renderImgTag ? "block" : "hidden")}
        />
      )}
      <AvatarImage
        src={props.src ?? undefined}
        alt={props.alt}
        className={cn("object-cover object-center", renderImgTag ? "hidden" : "block")}
        onLoadingStatusChange={(status) => setRenderImgTag(status === "idle" || status === "loading")}
      />
      <AvatarFallback style={{ backgroundColor: props.primaryColor ?? "#000000" }}>
        {props.acronym ? (
          <span className="font-bold uppercase italic" style={{ color: props.secondaryColor ?? "#ffffff" }}>
            {props.acronym}
          </span>
        ) : (
          <BoringAvatar size="100%" colors={PLATFORM_COLORS[Platform.Base]} name={props.alt} />
        )}
      </AvatarFallback>
    </AvatarRoot>
  )
}
