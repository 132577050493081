import { useStats } from "@bracket/hooks/queries/useStats"
import { useCurrentUIConfig } from "@bracket/hooks/useCurrentUIConfig"
import { formatPrice } from "@bracket/lib/formatters"
import { cn } from "@bracket/lib/utils"
import { useAppStore } from "@bracket/stores"
import { useEffect, useState } from "react"
import { default as ReactMarquee } from "react-fast-marquee"

export function Marquee() {
  const activeContract = useAppStore((s) => s.activeContract)
  const { tournamentFullName } = useCurrentUIConfig(activeContract)
  const { data, refetch } = useStats(activeContract)
  const [isLoading, setIsLoading] = useState(true)

  // Only refetch stats when the contract changes
  useEffect(() => {
    async function updateStats() {
      setIsLoading(true)
      setTimeout(async () => {
        await refetch()
        setIsLoading(false)
      }, 800)
    }

    updateStats()
  }, [activeContract, refetch])

  return (
    <div className="relative flex h-[2.4rem] w-full items-center overflow-hidden bg-[#879EF2] text-[0.8125rem] text-white">
      <ReactMarquee
        speed={50}
        className={cn("tracking-wide opacity-0 transition-opacity duration-300", { "opacity-100": !isLoading })}
      >
        <span className="mx-3 font-bold uppercase italic tracking-wider">{tournamentFullName}</span>
        <span className="mx-2">
          PRIZE POOL: <strong>{formatPrice(data?.prizePool ?? 0)}</strong> DEGEN
        </span>
        <span className="mx-2">
          <span>
            OWNERS: <strong>{data?.uniqueOwners}</strong>
          </span>
        </span>
        <span className="mx-2">
          24H VOL: <strong>{formatPrice(data?.volume24h ?? 0)}</strong> DEGEN
        </span>
      </ReactMarquee>

      <div className="absolute z-10 -ml-2 flex h-full -skew-x-12 flex-col items-center justify-center bg-buy-blue pl-[.86rem] pr-2 backdrop-blur-[.1rem] transition-all duration-1000">
        <span className="ml-[.4rem] mr-[.32rem] skew-x-12 text-[1.125rem] font-bold uppercase italic">
          {activeContract}
        </span>
      </div>
    </div>
  )
}
